import React, { createContext, useContext, useState } from 'react';
import { translations } from '../config/translations';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  // 브라우저 언어 감지 및 기본값 설정
  const getInitialLanguage = () => {
    // 브라우저의 언어 코드를 가져옴 (예: 'ko', 'ko-KR', 'en-US' 등)
    const browserLang = navigator.language.split('-')[0];
    
    // translations 객체에 해당 언어가 있는지 확인
    if (Object.keys(translations).includes(browserLang)) {
      return browserLang;
    }
    
    // 일치하는 언어가 없으면 영어를 기본값으로 사용
    return 'en';
  };

  const [language, setLanguage] = useState(getInitialLanguage());

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
} 