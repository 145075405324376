import React, { useEffect, useCallback } from 'react';
import { useLanguage } from '../context/LanguageContext';
import { translations } from '../config/translations';
import { BsCheckCircleFill } from 'react-icons/bs';
import { FaNewspaper } from 'react-icons/fa';
import { SiHuggingface } from 'react-icons/si';

const Modal = ({ isOpen, onClose, title, description, tech, youtubeUrls, links }) => {
  const { language } = useLanguage();
  const t = translations[language];

  const handleEsc = useCallback((e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
      // 모달이 열릴 때 스크롤 방지
      document.body.style.overflow = 'hidden';
    }
    
    return () => {
      document.removeEventListener('keydown', handleEsc);
      // 모달이 닫힐 때 스크롤 복구
      document.body.style.overflow = 'unset';
    };
  }, [handleEsc, isOpen]);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const getIcon = (iconType, color) => {
    switch (iconType) {
      case 'check':
        return <BsCheckCircleFill size={20} color={color} />;
      case 'news':
        return <FaNewspaper size={20} color={color} />;
      case 'huggingface':
        return <SiHuggingface size={20} color={color} />;
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={handleBackdropClick}
    >
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative animate-modal-appear max-h-[90vh] overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h3 className="text-2xl font-bold mb-4">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        
        {tech.includes('VITS') && youtubeUrls && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            {youtubeUrls.map((url, index) => (
              <div key={index} className="aspect-video">
                <iframe
                  className="w-full h-full"
                  src={url}
                  title={`YouTube video ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </div>
        )}

        <div className="bg-gray-100 p-3 rounded">
          <p className="font-semibold">{t.techLabel}</p>
          <p>{tech}</p>
        </div>

        {links && (
          <div className="mt-6 space-y-3">
            {links.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-3 p-3 rounded hover:bg-gray-100 transition-colors"
              >
                <span className="flex-shrink-0">
                  {getIcon(link.iconType, link.iconColor)}
                </span>
                <span className="text-gray-700 hover:text-gray-900">
                  {link.title}
                </span>
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal; 