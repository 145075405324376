export const translations = {
  ko: {
    name: "고준서",
    nickname: "Chato (차토)",
    email: "비즈니스 문의:",
    role: "머신러닝 엔지니어",
    team: "강남언니팀",
    github: "GitHub",
    scholar: "Google Scholar",
    youtube: "YouTube",
    linkedin: "LinkedIn",
    blog: "블로그",
    book: "유튜브 강의 자료",
    education: "성균관대학교 인공지능융합학과 학/석사",
    introduction: "안녕하세요 4년차 머신러닝/인공지능 엔지니어입니다.",
    workDescription: "강남언니팀의 ML플랫폼을 구축하고, 다양한 머신러닝 모델을 개발하고 최적화하며 ML플랫폼의 철학을 만들어가고 있습니다.",
    lectures: "외부 강의",
    projects: "대표 프로젝트",
    techLabel: '사용 기술:',
    project1: {
      title: "강남언니팀 ML 플랫폼 구축",
      description: "피쳐스토어, 모델 성능 모니터링, 자동 배포 엔진 개발, 모델 로그 모니터링 시스템 구축 등을 통해 머신러닝의 각 컴포넌트가 유기적으로 연결되는 플랫폼을 구축하였습니다.",
      tech: "Python, Kubernetes, Airflow, FastAPI를 활용한 ML 플랫폼 구축",
      techLabel: '사용 기술:',
    },
    project2: {
      title: "음성합성 모델 연구/개발",
      description: "음성합성 모델을 연구하고 개발하였으며, 한국어 발음화 알고리즘 개발을 통해 음성합성의 품질을 향상시키는 연구를 수행하였습니다. 카티어스, SBS미디어넷, TV조선, 갤럭시 코퍼레이션 등 다양한 기업에 적용하였습니다.",
      tech: "PyTorch, VITS, KiwiTokenizer를 활용한 음성합성 모델 개발",
      techLabel: '사용 기술:',
    },
    project3: {
      title: "의료 이미지 인공지능 연구 및 개발",
      description: "의료 이미지 인공지능 연구 및 개발을 진행하였습니다. SD-OCT 이미지를 활용한 망막질환 예측모델을 개발하여 9편의 SCIE급 저널 개제와 한길안과병원에 솔루션 납품이라는 성과를 얻었습니다.",
      tech: "PyTorch, Computer Vision을 활용한 의료 영상 분석",
      techLabel: '사용 기술:',
    },
    project4: {
        title: "LLM/RAG 연구 개발",
        description: "LLM/RAG 연구 개발을 진행하였습니다. 성형코디네이서 서비스, 사투리 언어모델 JEJUMA 언어모델을 개발하였고, 원티드x네이버클라우드 프롬프톤에서 1등을 차지하였습니다.",
        tech: "LLM, RAG, LangChain을 활용한 인공지능 챗봇 개발", 
        techLabel: '사용 기술:',
      links: [
        {
          title: "개발한 프로젝트",
          url: "https://cosmetic.kojunseo.link/",
          iconType: "check",
          iconColor: "#20C997"
        },
        {
          title: "관련 기사",
          url: "https://m.ddaily.co.kr/page/view/2024073016092806180",
          iconType: "news",
          iconColor: "#718096"
        },
        {
          title: "Jejuma",
          url: "https://huggingface.co/JEJUMA/JEJUMA-002",
          iconType: "huggingface",
          iconColor: "#FFD21E"
        }
      ]
    },
    portfolio: "포트폴리오",
    portfolioSubject: "세부 포트폴리오 요청",
    portfolioBody: "어떤 목적에서 (채용, 프로젝트, 외주, 강의 등) 포트폴리오를 요청하시는지 알려주세요.",
    coffee: "커피챗",
  },
  en: {
    name: "Junseo Ko",
    nickname: "Chato Ko",
    email: "Business Inquiry:",
    role: "Machine Learning Engineer",
    team: "GangnamUnni Team",
    github: "GitHub",
    scholar: "Google Scholar",
    youtube: "YouTube",
    linkedin: "LinkedIn",
    blog: "Blog",
    book: "Lecture Notes",
    education: "BS/MS in Artificial Intelligence, Sungkyunkwan University",
    introduction: "Hello, I'm a Machine Learning/AI engineer with 4 years of experience.",
    workDescription: "I'm building ML platforms at GangnamUnni Team, developing and optimizing various machine learning models while establishing the philosophy of our ML platform.",
    lectures: "External Lectures",
    projects: "Featured Projects",
    techLabel: 'Technologies:',
    project1: {
      title: "ML Platform Development at GangnamUnni",
      description: "Built an integrated machine learning platform incorporating feature store, model performance monitoring, automated deployment engine, and model log monitoring systems.",
      tech: "ML Platform development using Python, Kubernetes, Airflow, FastAPI",
      techLabel: 'Technologies:',
    },
    project2: {
      title: "Speech Synthesis Research & Development",
      description: "Researched and developed speech synthesis models, improving quality through Korean pronunciation algorithms. Successfully implemented for various companies including Cartiers, SBS Medianet, TV Chosun, and Galaxy Corporation.",
      tech: "Speech synthesis model development using PyTorch, VITS, KiwiTokenizer",
      techLabel: 'Technologies:',
    },
    project3: {
      title: "Medical Image AI Research & Development",
      description: "Conducted medical image AI research and development. Developed retinal disease prediction models using SD-OCT images, resulting in 9 SCIE journal publications and solution delivery to Hangil Eye Hospital.",
      tech: "Medical image analysis using PyTorch and Computer Vision",
      techLabel: 'Technologies:',
    },
    project4: {
      title: "LLM/RAG Research & Development",
      description: "Conducted LLM/RAG research and development. Developed a plastic surgery coordinator service and JEJUMA dialect language model, winning first place in the Wanted x Naver Cloud Prompthon competition.",
      tech: "AI chatbot development using LLM, RAG, LangChain",
      techLabel: 'Technologies:',
      links: [
        {
          title: "Developed Project",
          url: "https://cosmetic.kojunseo.link/",
          iconType: "check",
          iconColor: "#20C997"
        },
        {
          title: "Related News",
          url: "https://m.ddaily.co.kr/page/view/2024073016092806180",
          iconType: "news",
          iconColor: "#718096"
        },
        {
          title: "Jejuma",
          url: "https://huggingface.co/JEJUMA/JEJUMA-002",
          iconType: "huggingface",
          iconColor: "#FFD21E"
        }
      ]
    },
    portfolio: "Portfolio",
    portfolioSubject: "Portfolio Request",
    portfolioBody: "Please let me know the purpose for which you are requesting my portfolio (e.g., hiring, project, freelance work, lecture, etc.).",
    coffee: "Coffee Chat",
  },
  zh: {
    name: "高俊瑞",
    nickname: "Chato Ko",
    role: "机器学习工程师",
    team: "GangnamUnni Team",
    email: "商务咨询:",
    education: "成均馆大学人工智能融合学科 学士/硕士",
    introduction: "您好，我是一名有4年经验的机器学习/人工智能工程师。",
    workDescription: "我在GangnamUnni团队构建ML平台，开发和优化各种机器学习模型，同时建立ML平台的理念。",
    github: "GitHub",
    scholar: "Google Scholar",
    youtube: "YouTube",
    linkedin: "LinkedIn",
    blog: "Blog",
    book: "Lecture Notes",
    techLabel: '技术:',
    lectures: "外部讲座",
    projects: "代表项目",
    project1: {
      title: "GangnamUnni团队ML平台构建",
      description: "通过构建特征存储、模型性能监控、自动部署引擎、模型日志监控系统等，建立了机器学习各组件有机连接的平台。",
      tech: "使用Python、Kubernetes、Airflow、FastAPI构建ML平台",
    },
    project2: {
      title: "语音合成模型研究与开发",
      description: "研究开发语音合成模型，通过韩语发音算法开发提高语音合成质量。已成功应用于Cartiers、SBS Medianet、TV朝鲜、Galaxy Corporation等多家企业。",
      tech: "使用PyTorch、VITS、KiwiTokenizer开发语音合成模型",
    },
    project3: {
      title: "医疗图像人工智能研究与开发",
      description: "进行医疗图像人工智能研究与开发。使用SD-OCT图像开发视网膜疾病预测模型，发表9篇SCIE级期刊论文，并向Hangil眼科医院提供解决方案。",
      tech: "使用PyTorch和计算机视觉进行医学图像分析",
    },
    project4: {
      title: "LLM/RAG研究与开发",
      description: "进行LLM/RAG研究与开发。开发了整形协调员服务和方言语言模型JEJUMA，在Wanted x Naver Cloud Prompthon比赛中获得第一名。",
      tech: "使用LLM、RAG、LangChain开发人工智能聊天机器人",
      techLabel: '技术:',
      links: [
        {
          title: "开发的项目",
          url: "https://cosmetic.kojunseo.link/",
          iconType: "check",
          iconColor: "#20C997"
        },
        {
          title: "相关新闻",
          url: "https://m.ddaily.co.kr/page/view/2024073016092806180",
          iconType: "news",
          iconColor: "#718096"
        },
        {
          title: "Jejuma",
          url: "https://huggingface.co/JEJUMA/JEJUMA-002",
          iconType: "huggingface",
          iconColor: "#FFD21E"
        }
      ]
    },
    portfolio: "Portfolio",
    portfolioSubject: "作品集请求",
    portfolioBody: "Please let me know the purpose for which you are requesting my portfolio (e.g., hiring, project, freelance work, lecture, etc.).",
    coffee: "喝咖啡聊聊天",
  }
}; 