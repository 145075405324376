import React from 'react';
import { useLanguage } from '../context/LanguageContext';

const LanguageSelector = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="fixed top-4 right-4 flex space-x-2">
      <button
        onClick={() => setLanguage('ko')}
        className={`p-2 rounded-lg flex items-center ${
          language === 'ko' ? 'bg-gray-200' : 'hover:bg-gray-100'
        }`}
      >
        <span className="mr-1">🇰🇷</span>
        <span className="text-sm">한국어</span>
      </button>
      <button
        onClick={() => setLanguage('en')}
        className={`p-2 rounded-lg flex items-center ${
          language === 'en' ? 'bg-gray-200' : 'hover:bg-gray-100'
        }`}
      >
        <span className="mr-1">🇺🇸</span>
        <span className="text-sm">English</span>
      </button>
      <button
        onClick={() => setLanguage('zh')}
        className={`p-2 rounded-lg flex items-center ${
          language === 'zh' ? 'bg-gray-200' : 'hover:bg-gray-100'
        }`}
      >
        <span className="mr-1">🇨🇳</span>
        <span className="text-sm">中文</span>
      </button>
    </div>
  );
};

export default LanguageSelector; 