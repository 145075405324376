import React, { useState } from 'react';
import Modal from './Modal';

const ProjectCard = ({ title, description, tech, links }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // TTS 프로젝트를 위한 YouTube URL 설정
  const youtubeUrls = tech.includes('VITS') ? [
    'https://www.youtube.com/embed/R8RnAbct0Wg',
    'https://www.youtube.com/embed/7Pe26oZV8zU',
    'https://www.youtube.com/embed/VpVX1zWkxRk',

  ] : null;

  return (
    <>
      <div 
        className="bg-gray-50 p-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow"
        onClick={() => setIsModalOpen(true)}
      >
        <h3 className="text-xl font-bold mb-3">{title}</h3>
        <p className="text-gray-600 mb-4 line-clamp-3">{description}</p>
        <p className="text-sm text-gray-500">{tech}</p>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        description={description}
        tech={tech}
        youtubeUrls={youtubeUrls}
        links={links}
      />
    </>
  );
};

export default ProjectCard; 